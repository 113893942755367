<template>
  <div class="vigor">
    <back></back>
    <div class="title p-l-30 p-r-30">{{user.score}}</div>
    <div class="f-12 p-l-30 p-r-30">我的元气</div>
<!--    <van-icon name="clear" class="close"/>-->
    <div class="logo">
<!--      <video width="100%" height="100%" loop-->
<!--             id="video1" autoplay-->
<!--             :poster="require('../assets/img/vigor.png')"-->
<!--        src="https://f.cdn-static.cn/24546_16396382657832.mp4?attname=ball.mp4"></video>-->
      <img :src="require('../assets/img/vigor.png')"/>
    </div>
    <div class="p-l-30 p-r-30">
      <van-swipe class="my-swipe" :loop="false" indicator-color="rgba(0, 0, 0, .3)">
        <van-swipe-item>
          <div class="item">
            <div class="m-b-10 bold"><van-icon name="warning-o" class="bold"/>&nbsp;&nbsp;元气</div>
            <div>
              轻松小镇内部推出的两种活动积分之一，数量有限，主要用于奖励购买绑定、使用按摩器并提供健康数据、或者积极参与社区互动的用户，作为对用户持续保持健康的激励，未来可用于兑换相关服务、福利、实物产品等。
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item">
            <div class="text-center">元气碎片(0/10)</div>
            <div class="chip">
              <img :src="require('../assets/img/qi.png')" width="100%" height="100%"/>
            </div>
            <p class="save-btn white f-17" @click="showMsg">合成</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-overlay :show="isShowBox" @click="isShowBox = false">
      <div class="wrapper box">
        <div class="block">
          <!--          <div class="close">-->
          <!--            <van-icon name="cross" size="15" color="#69686D" />-->
          <!--          </div>-->
          <div class="m-b-20 f-22 bold">合成成功</div>
          <div class="f-15 m-b-25">获得xx气</div>
          <div class="btn" @click="isShowBox = false">关闭</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Back from '../components/Back'
import { mapState } from 'vuex'
import { getWxConfig } from '../../tool'
export default {
  name: 'Vigor',
  components: { Back },
  data () {
    return {
      isShowBox: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  mounted () {
    getWxConfig('轻松小镇', '元气记录')
  },
  methods: {
    play () {
      const video = document.querySelector('#video1')
      video.play()
    },
    showMsg () {
      this.$toast('元气碎片不足，不能合成元气')
    }
  }
}
</script>

<style scoped lang="scss">
.vigor{
  height: 100vh;
  padding-top: 48px;
  color: black;
  position: relative;
  .close{
    position: absolute;
    right: 17px;
    top: 38px;
    font-size: 27px;
    color: #ECECEC;
  }
  .title{
    font-size: 43px;
    color: black;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
    img{
      height: 264px;
    }
  }

  .my-swipe{
    height: 275px;
    box-sizing: content-box;
    padding-bottom: 35px;
    .item{
      height: 100%;
      padding: 34px 26px;
      background: rgba(225, 235, 243, 0.71);
      border-image: linear-gradient(0deg, #FFFFFF, #F9F6F7, #FFFFFF) 1 1;
      background-image: url("../assets/img/energy-down.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 30px;
      overflow: hidden;
      .chip{
        height: 151px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
          width: 127px;
          height: 139px;
        }
      }
      .save-btn{
        background: black;
        height: 43px;
        line-height: 43px;
        margin: 0 50px;
        text-align: center;
        border-radius: 27px;
        transition: all 0.5s;
        letter-spacing: 5px;
        &:active{
          background: rgba(0,0,0,.7);
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 75%;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 50px 30px;
      .close{
        position: absolute;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #ECECEC;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn{
        font-size: 15px;
        border: 1px solid #000000;
        padding: 8px 15px;
        border-radius: 20px;
      }
    }
  }
}
</style>
